<!-- 话题帖子列表页面 -->
<template>
    <div class="topic-post-ctn">
        <div class="breadcrumb-ctn">
            <a-breadcrumb>
                <a-breadcrumb-item v-for="(route, index) in breadcrumbData" :key="route.path">
                    <router-link v-if="route.path" :to="route.path">
                        {{ route.breadcrumbName }}
                    </router-link>
                    <span v-else>
                        {{ route.breadcrumbName }}
                    </span>
                </a-breadcrumb-item>

            </a-breadcrumb>
        </div>
        <div class="topic-post-main-ctn">
            <div class="topic-title-ctn">
                <div class="topic-title">
                    {{ subjectDetail.subjectName }}
                </div>
                <div class="topic-description">
                    {{ subjectDetail.remark }}
                </div>
                <div class="info-and-operation">
                    <div class="info-ctn">
                        <div>
                            {{ subjectDetail.articles }} 条内容
                        </div>
                        <div class="gap-line">

                        </div>
                        <div>
                            {{ subjectDetail.followUsers }} 关注
                        </div>
                    </div>
                    <div class="operations">
                        <a-button type="primary" style="width: 88px;" :loading="isSubmitFollowing" v-if="!subjectDetail.isFollow" @click="handleToggleFollow"> + 关注
                        </a-button>
                        <a-button type="plain" class="cancel-btn" :loading="isSubmitFollowing"  v-else @click="handleToggleFollow"> {{  isRecentSub ? '已关注' : '取消关注'  }}
                        </a-button>
                    </div>
                </div>
            </div>
            <div class="post-list">
                <div v-if="!mainContentLoading && postList.length == 0" style="padding: 80px 50px;background: #fff;">
                    <a-empty description="暂无帖子"></a-empty>
                </div>
                <a-spin v-show="mainContentLoading && postList.length == 0" :spinning="mainContentLoading">
                    <div style="padding: 16px;">
                        <a-skeleton acitive avatar title :paragraph="{ rows: 4 }" />
                        <div style="height: 280px;background: #fff;">

                        </div>
                    </div>
                </a-spin>
                <a-spin :spinning="mainContentLoading && postList.length !== 0" tip="正在加载中...">
                    <div ref="listRef">
                        <div v-for="(item, index) in postList">
                            <PostItem :index="index" :postData="item"></PostItem>
                        </div>
                    </div>
                </a-spin>
                <div v-show="total != 0" class="pagination-ctn default-pagination">
                    <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total"
                        show-quick-jumper hideOnSinglePage style="margin: 20px;"/>
                </div>
            </div>
        </div>
        <a-back-top />
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue-demi';
import PostItem from '../components/postItem.vue';
import { getSubjectDetail, getSubjectArticles, changeSubjectFollow } from '@/api/paperManage.js';
import { message } from 'ant-design-vue';

export default {
    components: {
        PostItem
    },
    setup(props, { root }) {
        const { $route, $router } = root;

        const breadcrumbData = computed(() => {
            const href = $router.resolve({
                name: 'topicPost',
                query: {
                    id: $route.query.id
                }
            })
            return [
                {
                    path: '/bbs/index',
                    breadcrumbName: '论坛首页'
                },
                {
                    path: href.route.fullPath,
                    breadcrumbName: `# ${subjectDetail.value.subjectName}`
                }
            ]
        });

        const mainContentLoading = ref(true);   // 是否正在加载
        const postList = ref([]);   // 帖子列表
        const current = ref(1); // 当前页
        const total = ref(0); // 总页数
        const pageSize = ref(10); // 单页数目
        // 获取话题下的帖子列表
        const getList = async () => {
            const res = await getSubjectArticles({
                topicId: $route.query.id,
                pageNo: current.value,
                pageSize: pageSize.value
            });
            if(res.success) {
                postList.value = res.data.rows.map(user => {
                    return {
                        ...user,
                       // userName: user.createdUserName
                       avatar: user.userAvatar,
                       userId: user.createdUserId,
                    }
                });
                total.value = res.data.totalRows;
            }
        }
        // 页数改变
        const handlePageChange = async () => {
            mainContentLoading.value = true
           /*  window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            setTimeout(() => {
                mainContentLoading.value = false
            }, 2000) */
            await getList();
            mainContentLoading.value = false;
        }
        

        // 话题详情
        const subjectDetail = ref({
            
        })

        // 获取详情
        const getDeatail = async () => {
            const res = await getSubjectDetail({
                topicId: $route.query.id
            });
            if(res.success) {
                subjectDetail.value = res.data;
                document.title = `# ${subjectDetail.value.subjectName} - 论坛`
            }
        }



        onMounted(() => {
            getDeatail();
            handlePageChange();
        })


        const isSubmitFollowing = ref(false);   // 是否正在请求关注/取消关注

        const isRecentSub = ref(false);     // 最近关注        

        const handleToggleFollow = async () => {
            /* if(props.subjectData.isFollow) {

            } */
            isSubmitFollowing.value = true;
            const newVal = !subjectDetail.value.isFollow
            const formData = new FormData();
            formData.append('subjectId', $route.query.id)
            formData.append('isFollow', newVal)
            const res = await changeSubjectFollow(formData);
            isRecentSub.value = false;
            if(res.success) {
               /*  context.emit('onUpdate', {
                    ...props.subjectData,
                    isFollow:newVal
                }) */
                if(newVal) {
                    message.success('成功关注');
                    isRecentSub.value = true;
                }
                else {
                    message.info('已取消关注')
                }
                await getDeatail();
            }
            isSubmitFollowing.value = false;
        }

        return {
            breadcrumbData,
            mainContentLoading,
            postList,
            current,
            total,
            pageSize,
            handlePageChange,
            subjectDetail,
            handleToggleFollow,
            isSubmitFollowing,
            isRecentSub
        }
    }
}

</script>

<style lang="less" scoped>
.topic-post-ctn {
    width: calc(100% - 300px);
    margin: auto;
    margin-bottom: 50px;

    .pagination-ctn {
       // padding: 20px;
        background: #fff;
    }
}

.breadcrumb-ctn {
    margin-top: 12px;
    //width: calc(100% - 300px);
}

.topic-post-main-ctn {
    margin-top: 12px;

    //width: calc(100% - 300px);
    .topic-title-ctn {
        padding: 20px;
        min-height: 100px;
        background: transparent;
        border: 0.5px solid #EBEBEB;
        border-radius: 4px;
        position: relative;
        z-index: 300;

        &::before {
            position: absolute;
            content: '';
            z-index: -200;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, #D6E1F4 0%, #EBF0FB 100%);
            /*  background-image: url('../../../assets/bbs/topic.png');
            background-position: top -23px right 0px;
            background-repeat: no-repeat; */
        }

        &::after {
            position: absolute;
            content: '';
            z-index: -200;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url('../../../assets/bbs/topic.png');
            background-position: top -36px right 0px;
            background-repeat: no-repeat;
        }

        /* background-image: url('../../../assets/bbs/topic.png');
        background-position: top -23px right 0px;
        background-repeat: no-repeat; */
        .topic-title {
            color: #325798;
            font-size: 20px;
            line-height: 30px;
        }

        .topic-description {
            color: rgba(0, 0, 0, 0.65);
            margin-top: 8px;
        }

        .info-and-operation {
            margin-top: 20px;
            display: flex;
            align-items: center;

            .info-ctn {
                width: 0;
                flex: 1;
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.45);

                .post-count {}

                .gap-line {
                    margin: 0 16px;
                    background: rgba(0, 0, 0, 0.45);
                    height: 16px;
                    width: .5px;
                }

                .focus-count {}
            }

            .operations {
                margin-left: 20px;
                padding-right: 14px;

                .cancel-btn {
                    border: 0.5px solid transparent;
                    background: #F6F6F6;
                    color: rgba(0, 0, 0, 0.65);
                    transition: .3s all ease-in-out;
                    border-radius: 4px;

                    &:hover {
                        color: @srims-primary-color;
                        opacity: 0.8;
                        background: #fff;
                        border: 0.5px solid @srims-primary-color;
                    }
                }
            }
        }
    }

    .post-list {
        margin-top: 20px;
        border: .5px solid #EBEBEB;
    }
}
</style>